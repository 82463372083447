<div class="qr-wrapper">
  <div class="qr-header">
    <h1>
      LANCEMENT DE L'APPLICATION MOBILE
      <span> MY BUSINESS SGMA</span>
    </h1>
    <p>
      Désormais, <span>MY</span><span>BUSINESS</span> est disponible en
      Application Mobile pour la consultation de vos comptes SGMA et de vos
      cartes. Téléchargez la sur les stores
    </p>
  </div>
  <div class="qr-container">
    <div class="ios-qr">
      <img class="qr-img" src="../../../../../assets/images/QRCode-iOS.png" alt="ios" srcset="" />
      <img src="../../../../../assets/images/ios-btn.svg" alt="ios-btn" srcset="" />
    </div>
    <div class="android-qr">
      <img class="qr-img" src="../../../../../assets/images/QRCode-android.png" alt="android" srcset="" />
      <img src="../../../../../assets/images/android-btn.svg" alt="android-btn" srcset="" />
    </div>
  </div>
  <img class="circle-top-left" src="../../../../../assets/images/qr-circle-top-left.svg" alt="qr-circle-top-left" />
  <img class="circle-top-right" src="../../../../../assets/images/qr-circle-top-right.svg" alt="qr-circle-top-right" />
  <img class="circle-bottom-right" src="../../../../../assets/images/qr-circle-bottom-right.svg"
    alt="qr-circle-bottom-right" />
</div>
<!-- <div class="mention-legal">
  <p>
    * L’application mobile permet la consultation des comptes SGMA. Les comptes
    offshore seront disponibles très prochainement.
  </p>
</div> -->
<div class="copyright-text">
  <p>Société Générale Maroc - {{ currentYear }}</p>
  <!-- <img
    class="circle-bottom-right"
    src="../../../../../assets/images/qr-circle-bottom-right.svg"
    alt="qr-circle-bottom-right"
  /> -->
</div>