<div class="list-container">
  <div class="list-content" *ngIf="!openChargeCardFlag">
    <div class="list-header">
      <h2 class="title-heading">LISTE DES CARTES ESCALE PRO</h2>
      <div class="list-filter">
        <span>Filtre</span>
        <button (click)="openFilterPopup()" [ngClass]="countFilterRes() != '' ? 'btn-filter-res' : ''"
          class="btn-filter" [attr.data-after]="countFilterRes()">
          <img src="/assets/images/filter.svg" alt="filter-icon" />
        </button>
      </div>
    </div>

    <div class="loader" *ngIf="showLoader">
      <img class="loader-icon" src="/assets/images/Ellipsis-2s-80px.gif" alt="" />
    </div>

    <div class="card-list">
      <div class="card-section" (click)="selectCard(card)" *ngFor="let card of currentCardEscaleProList" [ngClass]="{
          'selected-card':
            selectedCard.card &&
            card.cardNumber === selectedCard.card.cardNumber
        }">
        <div class="card-section1">
          <p>{{ card.embossedName }}</p>
          <button class="button-detail" (click)="openDetails(card, $event)">
            <img src="/assets/images/oeil-plus.svg" alt="filter-icon" />
          </button>
        </div>
        <div class="card-section2">
          <div>
            <p class="title">N° de la carte</p>
            <p class="card-number">{{ card.cardNumber }}</p>
          </div>
          <div>
            <p class="title">Solde</p>
            <p class="card-sold">
              {{ card.balance | currency : " " : "" : "" : "fr" }} MAD
            </p>
          </div>
          <div>
            <p class="title">Date d'expiration</p>
            <p class="card-expiration">
              {{ card.expiryDate | date : "dd/MM/yyyy" }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="action-container">
      <button (click)="chargeCard()" [disabled]="!(selectedCard.card && selectedCard.card.cardNumber !== '')">
        CHARGER
      </button>
    </div>
  </div>

  <app-ep-charge-card *ngIf="openChargeCardFlag" [selectedCustomerNumber]="selectedCustomerNumber"
    [selectedCard]="selectedCard" [accountToBeDebited]="selectAccountToBeDebited"
    (closeTab)="openChargeCardFlag = false"></app-ep-charge-card>
  <!-- <app-pagination
    [page]="page"
    (getPage)="getCurrentPageCards()"
  ></app-pagination> -->
</div>

<!-------------------------------- Error management --------------------------------------->
<app-message-popin [openPopinFlag]="displayError" [message]="openPopinMessage" [success]="openPopinMessageSuccess"
  (popinClosedEvent)="closePopin()"></app-message-popin>
<!----------------------------------------------------------------------------------------->

<!-------------------------------- Signature management ----------------------------------->
<app-signature *ngIf="openPopinSignatureFlag" [challengeCode]="challengeCode" [challengeKey]="challengeKey"
  [loaderMode]="isPendingRefreshChalCodBtn || isSignaturePending" (refreshChallengeCode)="refreshChallengeCode()"
  (closeSignaturePopup)="closeSignaturePopin()" (onSign)="signHandler($event)"></app-signature>
<!----------------------------------------------------------------------------------------->