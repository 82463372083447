import { createAction, props } from '@ngrx/store';

import { ConsultCardsDTO } from '../../../../models/ConsultCardsDTO';
import { CardConsultSearchRequestDTO } from 'src/app/models/CardConsultSearchRequestDTO';
import { HistoricCardResponse } from 'src/app/modules/consult-cards/models/historic-card-response.model';
import { RequestHistoricCard } from 'src/app/modules/consult-cards/models/request-historic-card.model';
import { ConsultCardsResponseDTO } from '../../../../models/ConsultCardsResponseDTO';

export const fetchCards = createAction(
  '[CONSULT CARD] Fetch Cards From CONSULT Cards Listing',
  props<{
    customerNumber: string;
    cardFilterDto: CardConsultSearchRequestDTO;
  }>()
);

export const fetchCardsFilter = createAction(
  '[CONSULT CARD] Fetch Cards From CONSULT Cards Listing by filter',
  props<{
    customerNumber: string;
    cardFilterDto: CardConsultSearchRequestDTO;
  }>()
);

export const fetchCardsSuccess = createAction(
  '[CONSULT CARD] Fetch Cards From CONSULT Cards Listing Success',
  props<{ cards: ConsultCardsResponseDTO }>()
);

export const fetchCardsFailed = createAction(
  '[CONSULT CARD] Fetch Cards From CONSULT Cards Listing Failed',
  props<{ error: any }>()
);

export const getCardOperations = createAction(
  '[CONSULT CARD] Get Card Operations From CONSULT Cards Listing',
  props<{ customerNumber: string; requestHistoricCard: RequestHistoricCard }>()
);

export const getCardOperationsSuccess = createAction(
  '[CONSULT CARD] Get Card Operations From CONSULT Cards Listing Success',
  props<{ op: HistoricCardResponse }>()
);

export const getCardOperationsFailed = createAction(
  '[CONSULT CARD] Get Card Operations From CONSULT Cards Listing Failed',
  props<{ error: any }>()
);

export const selectCard = createAction(
  '[CONSULT CARD] select card From Consult Cards Listing',
  props<{ card: ConsultCardsDTO }>()
);
