import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ConsultCardsOperationsDTO } from 'src/app/models/ConsultCardsOperationsDTO';
import { Store } from '@ngrx/store';
import { selectCardOperations } from 'src/app/core/store/state/consult-cards';
import { HistoricCardResponse } from '../models/historic-card-response.model';
import { DatePipe } from '@angular/common';
import { RequestHistoricCard } from '../models/request-historic-card.model';
import { State } from 'src/app/core/store/state';
import { getCardOperations } from 'src/app/core/store/state/consult-cards';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConsultCardsDTO } from 'src/app/models/ConsultCardsDTO';
import { Page } from 'src/app/models/page';
import { MatDialog } from '@angular/material';
import { ConsultCardsOprerationsFilterComponent } from '../consult-cards-oprerations-filter/consult-cards-oprerations-filter.component';

@Component({
  selector: 'app-consult-cards-operations',
  templateUrl: './consult-cards-operations.component.html',
  styleUrls: ['./consult-cards-operations.component.scss'],
})
export class ConsultCardsOperationsComponent implements OnInit, OnDestroy {
  selectedTab: string = 'historique';
  private unsubscribe$ = new Subject<void>();

  checkhabilitation: boolean = true;
  messageError: string;
  openPopinMessageFlag: boolean = true;
  page = new Page();
  loading = false;
  historicCardResponse: HistoricCardResponse = new HistoricCardResponse();
  filterOn: boolean = false;

  @Input() currentCardSelectionner: ConsultCardsDTO;
  @Input() requestHistoric: RequestHistoricCard;

  operations: ConsultCardsOperationsDTO[] = [];
  filteredOperations: ConsultCardsOperationsDTO[] = [];

  filter = {
    longDescription: '',
    startDate: '',
    endDate: '',
    minAmount: null,
    maxAmount: null,
  };

  showFilterDialog = false;

  constructor(
    private store: Store<State>,
    private datePipe: DatePipe,
    private dialog: MatDialog
  ) {
    this.page.init();
    this.page.totalElements = 2;
  }

  ngOnInit() {
    this.openPopinMessageFlag = true;

    this.store
      .select(selectCardOperations)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((cards) => {
        if (cards && cards.operation && cards.operation.result) {
          if (cards.operation.result.success !== null) {
            this.checkhabilitation = cards.operation.result.success;
            this.messageError = cards.operation.result.message;
          }
          this.historicCardResponse = cards.operation.result;
          this.operations = this.historicCardResponse.historic.map((op) => ({
            ...op,
            cardNumber: this.requestHistoric.cardNumber,
            date: this.convertDate(op.date),
          }));
          this.filteredOperations = this.processOperations([
            ...this.operations,
          ]);

          this.loading = false;
        }
      });

    this.loadOperations();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadOperations(isPagination: boolean = false) {
    if (!isPagination) {
      this.page.currentPage = 1;
      this.requestHistoric.page = 1;
      this.requestHistoric.pageSize = 50;
    }

    this.loading = true;
    this.store.dispatch(
      getCardOperations({
        customerNumber: this.requestHistoric.customerNumber,
        requestHistoricCard: this.requestHistoric,
      })
    );
  }

  onScroll(event: any) {
    const element = event.target;
    const reachedBottom =
      element.scrollHeight - element.scrollTop === element.clientHeight;

    if (
      reachedBottom &&
      !this.loading &&
      this.operations.length < this.page.totalElements
    ) {
      this.page.currentPage++;
      this.loadOperations(true);
    }
  }

  convertDate(dateStr: string): string {
    const [day, month, year] = dateStr.split(/[/ ]/);
    const date = new Date(+year, +month - 1, +day);
    return date.toISOString().split('T')[0]; // Format : yyyy-MM-dd
  }

  applyFilter() {
    this.filteredOperations = this.processOperations(
      this.operations.filter((operation) => {
        const operationDate = new Date(operation.date).setHours(0, 0, 0, 0);
        const startDate = this.filter.startDate
          ? new Date(this.filter.startDate).setHours(0, 0, 0, 0)
          : null;
        const endDate = this.filter.endDate
          ? new Date(this.filter.endDate).setHours(0, 0, 0, 0)
          : null;

        const matchesStartDate = startDate ? operationDate >= startDate : true;
        const matchesEndDate = endDate ? operationDate <= endDate : true;

        const matchesLongDescription = this.filter.longDescription
          ? operation.longDescription
              .toLowerCase()
              .includes(this.filter.longDescription.toLowerCase())
          : true;

        const matchesMinAmount =
          this.filter.minAmount !== null
            ? operation.convertedAmount >= this.filter.minAmount
            : true;

        const matchesMaxAmount =
          this.filter.maxAmount !== null
            ? operation.convertedAmount <= this.filter.maxAmount
            : true;

        return (
          matchesLongDescription &&
          matchesStartDate &&
          matchesEndDate &&
          matchesMinAmount &&
          matchesMaxAmount
        );
      })
    );

    this.showFilterDialog = false;
  }

  resetFilter() {
    this.filter = {
      longDescription: '',
      startDate: '',
      endDate: '',
      minAmount: null,
      maxAmount: null,
    };
    this.filteredOperations = [...this.operations];
  }

  openFilterPopup(): void {
    const dialogRef = this.dialog.open(ConsultCardsOprerationsFilterComponent, {
      data: { filter: this.filter },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (
        result &&
        (result.endDate ||
          result.startDate ||
          result.longDescription ||
          result.maxAmount !== null ||
          result.minAmount !== null)
      ) {
        this.filter = result;
        this.filterOn = true;
        this.applyFilter();
      }
    });
  }

  countFilterRes(): string {
    return this.filterOn && this.filteredOperations
      ? this.filteredOperations.length + ''
      : '';
  }
  renitialiser() {
    this.resetFilter();
    this.applyFilter();
    this.filterOn = false;
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
  }

  openFilter() {
    this.showFilterDialog = true;
  }

  closeFilter() {
    this.showFilterDialog = false;
  }
  closePopin() {
    this.openPopinMessageFlag = false;
  }
  processOperations(
    filteredOperations: ConsultCardsOperationsDTO[]
  ): ConsultCardsOperationsDTO[] {
    return filteredOperations.map((operation) => {
      if (operation.side === 'D') {
        operation.convertedAmount = -Math.abs(operation.convertedAmount);
        operation.transactionAmount = -Math.abs(operation.transactionAmount);
      }
      return operation;
    });
  }
}
