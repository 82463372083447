import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { navigateToApp } from 'src/app/models/applications-enum';
import { UserService } from 'src/app/service/user.service';
import { ServicesService } from 'src/app/service/services.service';
import { SoftTokenActivationService } from 'src/app/service/soft-token-activation.service';
import { Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { Application } from '../../../models/application';
import { KeycloakService } from 'keycloak-angular';
import { Client } from '../../../models/client';
import { Services } from 'src/app/models/services';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { selectConnectedUser, selectUserApplications } from 'src/app/core/store/state/user';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  animations: [
    // animation triggers go here
    trigger('openClose', [
      state(
        'open',
        style({ opacity: 1, visibility: 'visible', height: '100%' })
      ),
      transition('void => *', [
        style({ opacity: 0, visibility: 'hidden', height: '50%' }),
        animate(100),
      ]),
      transition('* => void', [
        animate(
          100,
          style({ opacity: 0, visibility: 'hidden', height: '50%' })
        ),
      ]),
    ]),
  ],
})
export class ApplicationsComponent implements OnInit {
  apps = [];
  showActivatedServices: boolean = true;
  public user: User;
  public cguAccepted: boolean = false;
  public userApps: Application[];

  public othersApps: Application[];
  public services: Services[];

  public selectedApplication: Application;

  public showNewsDetails = false;
  customerNumber: string;

  public status = false;
  clients: Client[];
  msg: string;

  selectUserApplications$ = this.store.select(selectUserApplications);
  selectConnectedUser$ = this.store.select(selectConnectedUser);
  selectGroupedApplications$=this.store.select(selectUserApplications);
  constructor(
    private userService: UserService,
    private servicesService: ServicesService,
    private keycloakService: KeycloakService,
    private softTokenActivationService: SoftTokenActivationService,
    private router: Router,
    private store: Store<State>,
  ) {}
 

  ngOnInit() {
    this.msg = null;
    this.selectUserApplications$.subscribe((items)=>{
      this.userApps=items;
    })
    this.selectConnectedUser$.subscribe((user) => (this.user = user));
  }

  updateShowedServices() {
    this.showActivatedServices = !this.showActivatedServices;
  }


  getCgu(): boolean {
    if (this.cguAccepted) return true;
    const cguValue = this.user.cguAccepted;
    if (cguValue) {
      return cguValue;
    }
    return true;
  }

  public navigateToApp(code) {
    navigateToApp(code, this.router);
  }

  public getIcon(code): {color: string, img: string} | null {
    switch (code) {
      case 'OTHER_CHANEL': {
        return  {
          color: '#F2E8FF',
          img: './assets/images/services/ic_Signature_Autre_canaux.svg '
        }
      }
      case 'REPORTING': {
        return {
          color: '#E8FFE9',
          img: './assets/images/services/ic_Gestion_Des_documents_bancaires.svg'
        };
      }
      case 'SOGETRADE': {
        return {
          color: '#FFF9E8',
          img: './assets/images/services/ic_Operations_internationales.svg'
        };
      }
      case 'PROVISION': {
        return {
          color: '#E8FAFF',
          img: './assets/images/services/ic_mise_dispo.svg'
        };
      }
      case 'EXTERNAL_TRANSFER': {
        return {
          color: '#FDFFE8',
          img: './assets/images/services/ic_other_canals.svg'
        };
      }
      case 'DIRECT_DEBIT': {
        return {
          color: '#E8FFFA',
          img: './assets/images/services/ic_Prélèvement.svg'
        };
      }
      case 'ESCALE_PRO': {
        return {
          color: '#FFE8F8',
          img: './assets/images/services/ic-Gestion-des-comptes-et-opérations-domestiaques.svg'
        };
      }
      case 'SOGECASHNET': {
        return {
          color: '#ECEDFF',
          img: './assets/images/services/ic-Gestion-des-comptes-et-opérations-domestiaques.svg'
        };
      }
      case 'ESIGN': {
        return {
          color: '#F2E8FF',
          img: './assets/images/services/ic_e-signature.svg'
        };
      }
      case 'CONSULTATION_CARD': {
        return {
          color: '#E8FAFF',
          img: './assets/images/services/ic_Gestion_Des_cartes_bancaires.svg'
        };
      }
      case 'SOGE_VALEURS': {
        return {
          color: '#E8FFE9',
          img: './assets/images/services/ic_Gestion_actifs.svg'
        };
      }
      default: {
        return null;
      }
    }
  }

  public showDetails(): void {
    this.showNewsDetails = true;
  }

  public cancel(): void {
    this.showNewsDetails = false;
  }

  confirmSelection() {
    this.userService.acceptCGU().subscribe(() => {
      this.cguAccepted = true;
    });
  }

  logout() {
    localStorage.clear();
    this.keycloakService.logout();
  }
}
