<div class="applications-container">
  <div class="applications-container-header">
    <h1>ACCÉDEZ À VOS <span>SERVICES</span></h1>
  </div>
  <app-applications></app-applications>

  <!-- <div class="side-actions">
    <button
      mat-button
      [ngClass]="a.menuOpen && 'active'"
      #a="matMenuTrigger"
      [matMenuTriggerFor]="aboveMenu"
    >
      <div id="circle">
        <img
          [src]="
            a.menuOpen
              ? '../../../../assets/images/icone actualite active.svg'
              : '../../../../assets/images/icone actualite default.svg'
          "
          alt=""
          srcset=""
        />
      </div>
    </button>
    <button
      mat-button
      [ngClass]="t.menuOpen && 'active'"
      #t="matMenuTrigger"
      [matMenuTriggerFor]="belowMenu"
    >
      <div id="circle">
        <img
          [src]="
            t.menuOpen
              ? '../../../../assets/images/contact active.svg'
              : '../../../../assets/images/contact default.svg'
          "
          alt=""
          srcset=""
        />
      </div>
    </button>
  </div> -->

  <!-- <mat-menu
    #aboveMenu="matMenu"
    yPosition="below"
    xPosition="before"
    class="actionsNews"
  >
    <app-news-elem
      headerTitle="CAMPAGNE COLLECTE DES BILANS FISCAUX"
      description="While the lemurs of Madagascar may understan derstan While the lemurs of While the lemurs of Mad…"
      link="sgma.ma"
    ></app-news-elem>
    <app-news-elem
      headerTitle="CAMPAGNE COLLECTE DES BILANS FISCAUX"
      description="While the lemurs of Madagascar may understan derstan While the lemurs of While the lemurs of Mad…"
      link="sgma.ma"
    ></app-news-elem>
    <app-news-elem
      headerTitle="CAMPAGNE COLLECTE DES BILANS FISCAUX"
      description="While the lemurs of Madagascar may understan derstan While the lemurs of While the lemurs of Mad…"
      link="sgma.ma"
    ></app-news-elem>
  </mat-menu>

  <mat-menu
    #belowMenu="matMenu"
    yPosition="below"
    xPosition="before"
    class="actionsContact"
  >
    <app-footer-elem
      image="/assets/images/help.svg"
      title="Assistance Cash Management"
      description="Lundi au Vendredi de 8h00 à 16h30"
    ></app-footer-elem>
    <app-footer-elem
      image="/assets/images/phone.svg"
      title="Téléphone"
      description="05 22 43 30 / 05 22 43 41 63"
    ></app-footer-elem>
    <app-footer-elem
      image="/assets/images/mail.svg"
      title="Adresse électronique"
      description="cashmanagementmaroc.assistance@socgen.com"
    ></app-footer-elem>
  </mat-menu> -->
</div>
<div class="infos-container">
  <app-footer-list></app-footer-list>
</div>
