import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { interval, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import * as ConsultCardActions from './consult-cards.actions';
import { ConsultCardService } from '../../../../service/ConsultCardService';

@Injectable()
export class ConsultCardsEffects {
  constructor(
    private actions$: Actions<any>,
    private consultCardService: ConsultCardService
  ) {}

  fetchCards$ = createEffect(() =>
    this.actions$.pipe(
      // you can pass in multiple actions here that will trigger the same effect
      ofType(
        ConsultCardActions.fetchCards,
        ConsultCardActions.fetchCardsFilter
      ),
      switchMap((action) =>
        this.consultCardService
          .getCards(action.customerNumber, action.cardFilterDto)
          .pipe(
            map((cardsResponse) => {
              return ConsultCardActions.fetchCardsSuccess({
                cards: cardsResponse,
              });
            }),
            catchError((error) =>
              of(ConsultCardActions.fetchCardsFailed({ error: error }))
            )
          )
      )
    )
  );

  getCardOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConsultCardActions.getCardOperations),
      switchMap((action) =>
        this.consultCardService
          .getCardOperations(action.requestHistoricCard)
          .pipe(
            map((operation) => {
              return ConsultCardActions.getCardOperationsSuccess({
                op: operation,
              });
            }),
            catchError((error) =>
              of(ConsultCardActions.fetchCardsFailed({ error: error }))
            )
          )
      )
    )
  );
}
