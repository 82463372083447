<div class="apps-list">
  <ng-container *ngFor="let app of userApps; let index = index">
    <div class="app-item">
      <div
        class="icon-container"
        [style.background-color]="getIcon(app?.code).color"
      >
        <img [src]="getIcon(app?.code).img" alt="Application Image" />
      </div>
      <div class="app-item-content">
        <h2>{{ app?.name }}</h2>
        <p>{{ app?.description }}</p>
      </div>
      <div class="app-item-link" (click)="navigateToApp(app.code)">
        <span>ACCÉDER AU SERVICE</span>
        <img
          class="default-arrow-btn"
          src="assets/images/arrow-go-default.svg"
          alt="ACCÉDER AU SERVICE IMAGE"
        />
        <div class="hover-btn">
          <img
            src="assets/images/arrow-go.svg"
            alt="ACCÉDER AU SERVICE IMAGE"
          />
        </div>
      </div>
      <svg
        class="wave-vector"
        xmlns="http://www.w3.org/2000/svg"
        width="588.815"
        height="381.859"
        viewBox="0 0 588.815 381.859"
      >
        <path
          id="Tracé_12803"
          data-name="Tracé 12803"
          d="M27.774,426.619c30.041,43.783,161.572,73.715,198.461,56.56S314.8,412,372.8,417.786s71.655,52.762,131.491,99.187-182.209,128.4-182.209,128.4S-118.874,613.745-71.44,503.012-36.546,200.318,35.613,284.087-2.268,382.836,27.774,426.619Z"
          transform="translate(75.01 -263.517)"
          [attr.fill]="getIcon(app?.code).color"
          opacity="0.4"
        />
      </svg>
    </div>
  </ng-container>
</div>
