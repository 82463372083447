<div class="wrapper">
  <div class="header-container">
    <h2>A PROPOS DE LA CARTE</h2>
    <button type="button" class="close-btn" (click)="close()">
      <img src="assets/images/close-white.svg" alt="" />
    </button>
  </div>
  <div class="content-container">
    <div class="caracteristic">
      <div class="header">
        <h3>Caractéristiques</h3>
      </div>
      <div class="body">
        <div>
          <label>Nom du porteur</label>
          <p>{{ cardDetails.fullName }}</p>
        </div>
        <div>
          <label>Type de carte</label>
          <p>VISA - Escale pro</p>
        </div>
        <div>
          <label>Numéro de la carte</label>
          <p>{{ cardDetails.cardNumber }}</p>
        </div>
        <div>
          <label>Date d'expiration</label>
          <p>
            {{ cardDetails.expirationDate | date : "dd/MM/yyyy" }}
          </p>
        </div>
      </div>
    </div>
    <div class="plafond">
      <div class="header">
        <h3>Plafonds </h3>
      </div>
      <div class="body">


        <div>
          <label>Solde chargé</label>
          <p>
            {{
            cardDetails.chargedBalance | currency : " " : "" : "" : "fr"
            }}MAD
          </p>
        </div>
        <div>
          <label>Plafond Retrait</label>
          <p>
            {{ cardDetails.ceilingWithdrawalInternational | currency : " " : "" : "" : "fr" }}
            MAD
          </p>
        </div>
        <div>
          <label>Plafond Achat</label>
          <p>
            {{ cardDetails.ceilingPaymentInternational | currency : " " : "" : "" : "fr" }}
            MAD
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button (click)="chargeCard()">CHARGER</button>
  </div>
</div>