import { ConsultCardsOperationsDTO } from './consult-cards-operations-dto.model';
export class HistoricCardResponse {
  public page: number;
  public pageSize: number;
  public totalSize: number;
  public firstDate: string;
  public success: boolean;
  public message: string;
  public historic: ConsultCardsOperationsDTO[];
}

