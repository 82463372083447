import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ClickOutsideModule } from 'ng4-click-outside';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { frLocale } from 'ngx-bootstrap/locale';
import { MomentModule } from 'ngx-moment';
import { NgxSelectModule } from 'ngx-select-ex';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SqueezeBoxModule } from 'squeezebox/dist';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppIframeComponent } from './components/app-iframe/app-iframe.component';
import { AppPopupComponent } from './components/app-popup/app-popup.component';
import { CoursComponent } from './components/cours/cours.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LogoutPopinComponent } from './components/logout-popin/logout-popin.component';
import { NewsDetailsPopinComponent } from './components/news-details-popin/news-details-popin.component';
import { NewsComponent } from './components/news/news/news.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { UserService } from './service/user.service';
import { UserConfigService } from './service/user-config-service';
defineLocale('fr', frLocale);
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { PublicRootComponentComponent } from './components/common/public-root-component/public-root-component.component';
import { SecureRootComponentComponent } from './components/common/secure-root-component/secure-root-component.component';
import { SoftTokenComponent } from './components/soft-token/soft-token.component';
import { IsAuthorizedToDirective } from './core/directive/is-authorized-to.directive';
import { ProvisionModule } from './modules/provision-module/provision.module';
import { FileService } from './service/file.service';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { SharedModule } from './modules/shared-module/shared.module';
import { ExternalTransferModule } from './modules/external-transfer/external-transfer.module';
import { SoftTokenModuleModule } from './modules/soft-token-module/soft-token-module.module';
import { PopinModule } from './modules/popin/popin.module';
import { SoftTokenPopinComponent } from './modules/soft-token-module/soft-token-popin/soft-token-popin.component';
import { AssitanceDetailRequestComponent } from './modules/dashboard/assistance/assitance-detail-request/assitance-detail-request.component';
import { DirectDebitModule } from './modules/direct-debit/direct-debit.module';
import { EscaleProModule } from './modules/escale-pro/escale-pro.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID } from '@angular/core';

import { AssistanceComponent } from './modules/dashboard/assistance/assistance.component';
import { SoftTokenNewComponent } from './components/soft-token-new/soft-token-new.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ExpertsComponent } from './components/experts/experts.component';
import { BalanceSheetModule } from './modules/balance-sheet/balance-sheet.module';
import { PdfViewerModule } from 'ng2-pdf-viewer/public_api';
import { DialogCommonComponent } from './modules/popin/dialog-common/dialog-common.component';
import { SellCardModule } from './modules/sell-card/sell-card.module';
import { ESignModule } from './modules/e-sign/e-sign.module';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './core/store/state';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './core/store/state/user';
import { EscaleProEffects } from './core/store/state/escale-pro';
import { ConsultCardsEffects } from './core/store/state/consult-cards';
import { SogeValeurModule } from './modules/soge-valeur/soge-valeur.module';
import { SogeValeurEffects } from './core/store/state/soge-valeur';
import { SoftTokenNewOnboardingModule } from './modules/soft-token-new-onboarding/soft-token-new-onboarding.module';
import { ESignEffects } from './core/store/state/e-sign';
import { ConsultCardsModule } from './modules/consult-cards/consult-cards.module';
import { MatButtonModule } from '@angular/material';
import { MatMenuModule } from '@angular/material';
import { FooterElemComponent } from './modules/dashboard/footer/footer-elem/footer-elem.component';
registerLocaleData(localeFr, 'fr');

const keycloakService = new KeycloakService();

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NewsComponent,
    CoursComponent,
    AppPopupComponent,
    AppIframeComponent,
    NewsDetailsPopinComponent,
    ReportingComponent,
    IsAuthorizedToDirective,
    LogoutPopinComponent,
    SoftTokenComponent,
    PublicRootComponentComponent,
    SecureRootComponentComponent,
    ExpertsComponent,
    FooterElemComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    KeycloakAngularModule,
    SlickCarouselModule,
    ClickOutsideModule,
    AppRoutingModule,
    NgxSelectModule,
    SqueezeBoxModule,
    MomentModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ProvisionModule,
    ExternalTransferModule,
    MatDialogModule,
    SharedModule,
    SoftTokenModuleModule,
    DashboardModule,
    DirectDebitModule,
    EscaleProModule,
    SogeValeurModule,
    ESignModule,
    NgxDropzoneModule,
    MatTabsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    PopinModule,
    CommonModule,
    MatButtonModule,
    MatMenuModule,

    SellCardModule,
    MatInputModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    EffectsModule.forRoot([
      UserEffects,
      EscaleProEffects,
      ESignEffects,
      SogeValeurEffects,
      ConsultCardsEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    SoftTokenNewOnboardingModule,
    ConsultCardsModule,
  ],
  providers: [
    {
      provide: KeycloakService,
      useValue: keycloakService,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    UserService,
    FileService,
    { provide: LOCALE_ID, useValue: 'fr' },
  ],
  entryComponents: [
    AppComponent,
    SoftTokenPopinComponent,
    DialogCommonComponent,
    AssitanceDetailRequestComponent,
    AssistanceComponent,
    SoftTokenNewComponent,
  ],
  exports: [IsAuthorizedToDirective],
})
export class AppModule implements DoBootstrap {
  constructor(private keycloakService: KeycloakService) {}

  async ngDoBootstrap(app: any) {
    try {
      await this.keycloakService.init({
        config: {
          url: environment.keycloak.issuer,
          realm: environment.keycloak.realm,
          clientId: environment.keycloak.clientId,
        },
        initOptions: {
          onLoad: 'check-sso',
          checkLoginIframe: false,
        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: [
          'portal-customer/soft-token*',
          'portal-customer/otp*',
        ],
      });
      app.bootstrap(AppComponent);
    } catch (error) {
      console.error('Keycloak init failed', error);
    }
  }
}