import { Component, OnInit } from '@angular/core';
import { CallState } from 'src/app/models/CallState';
import { ClientService } from 'src/app/service/client.service';
import { UserService } from 'src/app/service/user.service';
import { Roles } from 'src/app/utils/roles';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { fetchEscaleProUserRoles } from 'src/app/core/store/state/escale-pro';
import { User } from 'src/app/models/user';
import { map, switchMap } from 'rxjs/operators';
import {
  selectConnectedUser,
  selectSelectedCustomerNumber,
} from 'src/app/core/store/state/user';
import { selectEscaleProUserRoles } from 'src/app/core/store/state/escale-pro';
import { UserRolesDTO } from 'src/app/models/UserRolesDto';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ep-dashboard',
  templateUrl: './ep-dashboard.component.html',
  styleUrls: ['./ep-dashboard.component.scss'],
})
export class EpDashboardComponent implements OnInit {
  title = 'Autorisation';
  tabActive = '';
  isDataLoaded = false;
  userCanConsult: boolean = false;
  messageError: string;
  userCanSign: boolean = false;
  userCanIniciate: boolean = false;
  checkUser: boolean = false;
  selectConnectedUser$ = this.store.select(selectConnectedUser);
  user: User;
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = '';
  selectEscaleProUserRoles$ = this.store.select(selectEscaleProUserRoles);

  constructor(private store: Store<State>, private router: Router) {}
  ngOnInit() {
    this.messageError =
      'Vous n’avez pas accès à ce service. Merci de vous rapprocher de votre gestionnaire de compte';
    this.selectConnectedUser$
      .pipe(
        switchMap((user) =>
          this.selectedCustomerNumber$.pipe(
            map((customerNumber) => [user, customerNumber])
          )
        )
      )
      .subscribe(([user, customerNumber]) => {
        if (user && customerNumber) {
          this.user = user;
          this.selectedCustomerNumber = customerNumber;
          this.store.dispatch(
            fetchEscaleProUserRoles({
              customerNumber: this.selectedCustomerNumber,
              username: this.user.username,
            })
          );
        }
      });

    this.selectEscaleProUserRoles$.subscribe((userRoles) => {
      if (userRoles && userRoles.result) {
        this.checkUserRoles(userRoles.result);
      }
    });
  }

  selectTab(tabLabel: string): void {
    this.tabActive = tabLabel;
  }

  isActive(tabLabel: string): boolean {
    return this.tabActive === tabLabel;
  }

  checkUserRoles(result: UserRolesDTO) {
    if (
      result.userRoles === null ||
      result.userRoles === undefined ||
      result.userRoles.length === 0
    ) {
      this.checkUser = true;
    }
    if (result.userRoles && result.userRoles.length > 0) {
      this.userCanSign = Roles.hasRole(result, Roles.ESCALE_PRO_SIGNATORY);

      this.userCanConsult =
        Roles.hasRole(result, Roles.ESCALE_PRO_INITIATOR) || this.userCanSign;

      this.userCanIniciate =
        Roles.hasRole(result, Roles.ESCALE_PRO_INITIATOR) || this.userCanSign;

      if (
        !this.userCanConsult &&
        !this.userCanIniciate &&
        !this.userCanIniciate
      ) {
        this.checkUser = true;
      }
    }

    if (this.userCanIniciate) this.tabActive = 'INIT';
    else if (this.userCanConsult || this.userCanSign)
      this.tabActive = 'TO-SIGN';
    else this.tabActive = 'ROLES-ERROR';
  }
  closePopin() {
    this.router.navigate(['/dashboard']);
  }
}
