import { Component, OnInit, Inject, Output, EventEmitter, ViewChildren, AfterViewInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { BusinessConfigsService } from 'src/app/service/business-configs.service';
import { AdvisorService } from 'src/app/service/advisor.service';
import { CafService } from 'src/app/service/caf.service';
import { Client } from 'src/app/models/client';
import { FormControl } from '@angular/forms';
import { ClientService } from 'src/app/service/client.service';
import { NavigationEnd, Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { AssistanceComponent } from 'src/app/modules/dashboard/assistance/assistance.component';
import { Store } from '@ngrx/store';
import { State } from 'src/app/core/store/state';
import { CustomerFreeAttributes } from 'src/app/models/customerFreeAttributes';
import { AccountCustomerDetails } from 'src/app/models/AccountCustomerDetails';
import { forkJoin, Observable } from 'rxjs';
import { navigateToApp } from 'src/app/models/applications-enum';
import { MatMenu } from '@angular/material/menu';
import {
  changeSelectedCustomerNumber,
  selectConnectedUser,
  selectSelectedCustomerNumber,
  selectUserApplications,
  selectUserClients,
} from 'src/app/core/store/state/user';
import { Application } from '../../models/application';

const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
  input !== null && input.tagName === 'IFRAME';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() show_or_hide:Boolean;
  data:Application[];

  // getLink(app: string): string {
  //   if(app=='ESCALE_PRO' || app=='ESIGN' || app=='OTHER_CHANEL' || app=='SOGECASHNET' || app=='SOGETRADE'){
  //     switch (app) {
  //       case 'ESCALE_PRO':
  //         return '/escale-pro';
  //       case 'ESIGN':
  //         return '/esign';
  //       case 'OTHER_CHANEL':
  //         return '/external-transfer';
  //       case 'SOGECASHNET':
  //         return '/toapp/SOGECASHNET'
  //       case 'SOGETRADE':
  //         return '/toapp/SOGETRADE'
  //       default:
  //         return '#';
  //      }
  //   }
  //   switch (app) {
  //     case 'CONSULTATION_CARD':
  //       return '/consult-cards';
  //     case 'MY STORE':
  //       return '/toapp/SOGECASHNET';
  //     case 'reporting':
  //       return '/reporting';
  //     case 'SOGE_VALEURS':
  //       return  '/soge-valeur';  
  //     case 'DIRECT_DEBIT':
  //       return '/direct-debit';
  //     case 'REPORTING':
  //       return '/reporting'  
  //     default:
  //       return '#';
  //   }
  // }
  
  public navigateToApp(code) {
    navigateToApp(code, this.router);
  }

  clients: Array<Client>;
  cardShow: boolean = false;
  codeAdvisor: string;
  public clientFormControl = new FormControl();
  public defaultClientFormControlValue: Client;
  showDropDownClientSelect = false;
  options: string[] = [];
  displayMutliclient: boolean = true;
  displayMsgMutliclient: boolean = false;
  customerFreeAttributes: CustomerFreeAttributes;
  public userFullName: string;
  @Output() logoutDetails = new EventEmitter<void>();
  HIDE_MODAL = 'hideModal';
  SHOW_MODAL = 'showModal';
  LIST = 'list';
  modeleModalData: { showClass: string; type: string } = {
    showClass: this.HIDE_MODAL,
    type: null,
  };
  public alphaNumericValue: string;
  public user: User;
  //constructor(private keycloakService: KeycloakService) { }
  show:boolean=true;
  selectConnectedUser$ = this.store.select(selectConnectedUser);
  selectedCustomerNumber$ = this.store.select(selectSelectedCustomerNumber);
  selectedCustomerNumber = '';
  selectUserClients$ = this.store.select(selectUserClients);
  selectUserApplications$ = this.store.select(selectUserApplications);
  displayedCustomerNumber: string;
  uniquedata=[];
  url!:string;
  constructor(
    @Inject(KeycloakService) service: KeycloakService,
    private keycloakService: KeycloakService,
    private cafService: CafService,
    private clientService: ClientService,
    private businessConfigsService: BusinessConfigsService,
    private advisorService: AdvisorService,
    private router: Router,
    public dialog: MatDialog,
    private store: Store<State>
  ) { }


  ngOnInit() {
    this.url=this.router.url;
    this. selectUserApplications$.subscribe(item=>{
        this.data=item;
      })
    this.displayMutliclient = !(
      window.location.pathname &&
      window.location.pathname.includes('SOGECASHNET')
    );

    this.selectConnectedUser$.subscribe((user) => (this.user = user));

    this.selectedCustomerNumber$
      .pipe(
        switchMap((customerNumber) =>
          this.selectUserClients$.pipe(
            map((clients) => [customerNumber, clients])
          )
        )
      )
      .subscribe(([customerNumber, clients]) => {
        this.selectedCustomerNumber = customerNumber;
        this.clients = clients;
        if (this.selectedCustomerNumber && clients.length > 0) {
          if (!this.displayMutliclient && clients.length > 1) {
            this.displayMsgMutliclient = true;
          } else {
            this.displayMsgMutliclient = false;
          }
          this.showDropDownClientSelect = true;

          this.defaultClientFormControlValue =
            clients.find((client) => {
              return client.customerNumber === this.selectedCustomerNumber;
            }) || clients[0];

          this.displayedCustomerNumber =
            this.defaultClientFormControlValue.customerNumber;
          this.router.navigate(['/refreshPath', window.location.pathname]);
        }

        forkJoin([
          this.clientService.getClientCodeMarche(customerNumber),
          this.businessConfigsService.findBusinessConfig(
            'CARD_MARKET_CODE_AUTHORIZED'
          ),
          this.businessConfigsService.findBusinessConfig('CARD_CAF_AUTHORIZED'),
        ])
          .pipe(
            switchMap(
              ([clientCodeMarcheResult, cardMarketConfig, cardCafConfig]) => {
                this.customerFreeAttributes =
                  this.getCustomerFreeAttributeWithCodeM(
                    clientCodeMarcheResult
                  );

                if (customerNumber && cardMarketConfig) {
                  this.cardShow = cardMarketConfig.includes(
                    this.customerFreeAttributes.alphaNumericValue
                  );
                }

                if (this.cardShow && customerNumber && cardCafConfig) {
                  return this.cafService.findCafByClient(customerNumber).pipe(
                    switchMap((caf) => {
                      this.cardShow = cardCafConfig.includes(caf.code);
                      return []; // Observable vide pour compléter la chaîne
                    })
                  );
                }

                return []; // Observable vide pour compléter la chaîne
              }
            )
          )
          .subscribe();
      });
  }
  getCustomerFreeAttributeWithCodeM(
    accountDetails: AccountCustomerDetails
  ): CustomerFreeAttributes | undefined {
    return accountDetails.customerFreeAttributes.find(
      (attribute) => attribute.code === 'MCH'
    );
  }

  onSelectionChanges(displayedCustomerNumber): void {
    this.displayedCustomerNumber = displayedCustomerNumber[0].value;
    if (
      (this.displayedCustomerNumber != null || undefined) &&
      this.selectedCustomerNumber !== this.displayedCustomerNumber
    ) {
      this.store.dispatch(
        changeSelectedCustomerNumber({
          customerNumber: this.displayedCustomerNumber,
          userName: this.user.username,
        })
      );
    }
  }

  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }

  onClickedOutside(e: Event) {
    this.status = false;
  }
  logout() {
    localStorage.clear();
    this.keycloakService.logout();
  }

  public onDetails(): void {
    this.logoutDetails.emit();
  }

  selectReview(reviewId: string) {}

  callAdviser() {
    this.advisorService.menuStatus.next('-1');
    const dialogRefs = this.dialog.open(AssistanceComponent, {
      data: {},
      disableClose: true,
    });
  }

  openSideBar() {
    document.querySelector('[id="rightSideBar"]').classList.add('active');
  }
  sellcardRedirection() {
    this.router.navigate(['/sell-card']);
  }


}
