import { HistoricCardResponse } from 'src/app/modules/consult-cards/models/historic-card-response.model';
import { CallState } from '../../../../models/CallState';
import { ConsultCardsDTO } from '../../../../models/ConsultCardsDTO';
import { ConsultCardsOperationsDTO } from '../../../../models/ConsultCardsOperationsDTO';
import { ConsultCardsResponseDTO } from '../../../../models/ConsultCardsResponseDTO';

export interface ConsultCardsState {
  operations: {
    operation: { result: HistoricCardResponse; callState: CallState };
  };

  consultCardsListing: {
    cards: {
      result: ConsultCardsResponseDTO | null;
      callState: CallState;
    };
  };
}
export const initialState: ConsultCardsState = {
  operations: { operation: null },
  consultCardsListing: {
    cards: null,
  },
};
